.ant-collapse-item {
  padding-top: 70px; /* Adjust for navbar height */
  margin-top: -70px;
}

div.ant-collapse-item:target {
  /* border: 2px solid pink; */
  background-color: yellow !important;  /* Highlight color */
  transition: background-color 0.5s ease !important; /* Smooth fade-out */
}

.suggest:hover span {
    display:none
  }
  
  .suggest:hover::after {
    content:"Suggest";
  }


.secret-text {
    color: #fff;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    filter: blur(3px);
    transition: filter 0.5s ease-in-out, text-shadow 0.5s ease-in-out;
    cursor: pointer;
}
